<template>
  <div id="loading-bg">
      <div class="loading-logo">
        <img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/logoescrita_oraculo_02.svg">
      </div>
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import * as types from '../store/types'
export default {
  mounted() {
    this.getToken()

  },
  methods: {
    ...mapActions({
      loginByToken: types.GET_USER_DATA_CREDENTIALS
    }),
    ...mapMutations({
      logout: types.MUTATE_TOKEN_LOGOUT
    }),
    getToken() {
      this.logout()
      this.loginByToken({
        token: this.$route.params.token
      })
        .catch(error => {
          this.$router.push({ name: 'misc-not-authorized' })
        })
    }
  },
}
</script>
